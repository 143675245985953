export const ChattingNow = () => import('../../components/ChattingNow.vue' /* webpackChunkName: "components/chatting-now" */).then(c => wrapFunctional(c.default || c))
export const CommentCard = () => import('../../components/CommentCard.vue' /* webpackChunkName: "components/comment-card" */).then(c => wrapFunctional(c.default || c))
export const CommentCardH5 = () => import('../../components/CommentCardH5.vue' /* webpackChunkName: "components/comment-card-h5" */).then(c => wrapFunctional(c.default || c))
export const CompanyInfo = () => import('../../components/CompanyInfo.vue' /* webpackChunkName: "components/company-info" */).then(c => wrapFunctional(c.default || c))
export const DownloadDialog = () => import('../../components/DownloadDialog.vue' /* webpackChunkName: "components/download-dialog" */).then(c => wrapFunctional(c.default || c))
export const FileUpload = () => import('../../components/FileUpload.vue' /* webpackChunkName: "components/file-upload" */).then(c => wrapFunctional(c.default || c))
export const FindJobs = () => import('../../components/FindJobs.vue' /* webpackChunkName: "components/find-jobs" */).then(c => wrapFunctional(c.default || c))
export const FindJobsH5 = () => import('../../components/FindJobsH5.vue' /* webpackChunkName: "components/find-jobs-h5" */).then(c => wrapFunctional(c.default || c))
export const FooterUs = () => import('../../components/FooterUs.vue' /* webpackChunkName: "components/footer-us" */).then(c => wrapFunctional(c.default || c))
export const FooterUsH5 = () => import('../../components/FooterUsH5.vue' /* webpackChunkName: "components/footer-us-h5" */).then(c => wrapFunctional(c.default || c))
export const GmapsLittle = () => import('../../components/GmapsLittle.vue' /* webpackChunkName: "components/gmaps-little" */).then(c => wrapFunctional(c.default || c))
export const GmapsLittlePc = () => import('../../components/GmapsLittlePc.vue' /* webpackChunkName: "components/gmaps-little-pc" */).then(c => wrapFunctional(c.default || c))
export const Homepage = () => import('../../components/Homepage.vue' /* webpackChunkName: "components/homepage" */).then(c => wrapFunctional(c.default || c))
export const HomepageH5 = () => import('../../components/HomepageH5.vue' /* webpackChunkName: "components/homepage-h5" */).then(c => wrapFunctional(c.default || c))
export const Job = () => import('../../components/Job.vue' /* webpackChunkName: "components/job" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const MenuAdvertisement = () => import('../../components/MenuAdvertisement.vue' /* webpackChunkName: "components/menu-advertisement" */).then(c => wrapFunctional(c.default || c))
export const MenuAdvertisementH5 = () => import('../../components/MenuAdvertisementH5.vue' /* webpackChunkName: "components/menu-advertisement-h5" */).then(c => wrapFunctional(c.default || c))
export const MenuH5 = () => import('../../components/MenuH5.vue' /* webpackChunkName: "components/menu-h5" */).then(c => wrapFunctional(c.default || c))
export const News = () => import('../../components/News.vue' /* webpackChunkName: "components/news" */).then(c => wrapFunctional(c.default || c))
export const NewsCard = () => import('../../components/NewsCard.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const OpeningJobsList = () => import('../../components/OpeningJobsList.vue' /* webpackChunkName: "components/opening-jobs-list" */).then(c => wrapFunctional(c.default || c))
export const PostForm = () => import('../../components/PostForm.vue' /* webpackChunkName: "components/post-form" */).then(c => wrapFunctional(c.default || c))
export const PostFormH5 = () => import('../../components/PostFormH5.vue' /* webpackChunkName: "components/post-form-h5" */).then(c => wrapFunctional(c.default || c))
export const PostFormH5Two = () => import('../../components/PostFormH5Two.vue' /* webpackChunkName: "components/post-form-h5-two" */).then(c => wrapFunctional(c.default || c))
export const PostFormTwo = () => import('../../components/PostFormTwo.vue' /* webpackChunkName: "components/post-form-two" */).then(c => wrapFunctional(c.default || c))
export const Press = () => import('../../components/Press.vue' /* webpackChunkName: "components/press" */).then(c => wrapFunctional(c.default || c))
export const Review = () => import('../../components/Review.vue' /* webpackChunkName: "components/review" */).then(c => wrapFunctional(c.default || c))
export const ReviewH5 = () => import('../../components/ReviewH5.vue' /* webpackChunkName: "components/review-h5" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchH5 = () => import('../../components/SearchH5.vue' /* webpackChunkName: "components/search-h5" */).then(c => wrapFunctional(c.default || c))
export const SearchResult = () => import('../../components/SearchResult.vue' /* webpackChunkName: "components/search-result" */).then(c => wrapFunctional(c.default || c))
export const SearchResultH5 = () => import('../../components/SearchResultH5.vue' /* webpackChunkName: "components/search-result-h5" */).then(c => wrapFunctional(c.default || c))
export const SearchWrapper = () => import('../../components/SearchWrapper.vue' /* webpackChunkName: "components/search-wrapper" */).then(c => wrapFunctional(c.default || c))
export const SearchWrapperH5 = () => import('../../components/SearchWrapperH5.vue' /* webpackChunkName: "components/search-wrapper-h5" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const UserFeedback = () => import('../../components/UserFeedback.vue' /* webpackChunkName: "components/user-feedback" */).then(c => wrapFunctional(c.default || c))
export const UserFeedbackH5 = () => import('../../components/UserFeedbackH5.vue' /* webpackChunkName: "components/user-feedback-h5" */).then(c => wrapFunctional(c.default || c))
export const WebB = () => import('../../components/WebB.vue' /* webpackChunkName: "components/web-b" */).then(c => wrapFunctional(c.default || c))
export const WebBH5 = () => import('../../components/WebBH5.vue' /* webpackChunkName: "components/web-b-h5" */).then(c => wrapFunctional(c.default || c))
export const WebC = () => import('../../components/WebC.vue' /* webpackChunkName: "components/web-c" */).then(c => wrapFunctional(c.default || c))
export const WebCH5 = () => import('../../components/WebCH5.vue' /* webpackChunkName: "components/web-c-h5" */).then(c => wrapFunctional(c.default || c))
export const Wrapper = () => import('../../components/Wrapper.vue' /* webpackChunkName: "components/wrapper" */).then(c => wrapFunctional(c.default || c))
export const WrapperH5 = () => import('../../components/WrapperH5.vue' /* webpackChunkName: "components/wrapper-h5" */).then(c => wrapFunctional(c.default || c))
export const HireyB = () => import('../../components/hireyB.vue' /* webpackChunkName: "components/hirey-b" */).then(c => wrapFunctional(c.default || c))
export const HireyBh5 = () => import('../../components/hireyBh5.vue' /* webpackChunkName: "components/hirey-bh5" */).then(c => wrapFunctional(c.default || c))
export const HireyC = () => import('../../components/hireyC.vue' /* webpackChunkName: "components/hirey-c" */).then(c => wrapFunctional(c.default || c))
export const HireyCh5 = () => import('../../components/hireyCh5.vue' /* webpackChunkName: "components/hirey-ch5" */).then(c => wrapFunctional(c.default || c))
export const Recommend = () => import('../../components/recommend.vue' /* webpackChunkName: "components/recommend" */).then(c => wrapFunctional(c.default || c))
export const RecommendH5 = () => import('../../components/recommendH5.vue' /* webpackChunkName: "components/recommend-h5" */).then(c => wrapFunctional(c.default || c))
export const Remote = () => import('../../components/remote.vue' /* webpackChunkName: "components/remote" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
